export const InterviewEnglish = {
  tipsForRecording: 'Top tips for recording your video responses',
  instructions: {
    quietDistractionFree: 'Ensure you are in a quiet, distraction-free area.',
    checkChargeAndConnection: 'Check you have enough charge on your device and a good data connection.',
    practiceToFeelComfortable: 'Practice to feel comfortable with the process.',
    makeEyeContact: 'Make eye contact with the camera whenever possible.',
    grantAccessToCameraMicrophone: 'Remember to grant access to your camera and microphone if requested.'
  },
  navigation: {
    tryItOut: 'Try it out',
    getStarted: 'Get Started',
    backToInstructions: 'Go back to instructions',
    continue: 'Continue'
  },
  completeToSubmit: 'Complete the Video interview to submit your application',
  receivedPartially: 'Your application for {roleName} is received partially. Complete the video interview to submit your application.',
  ready: 'Ready',
  recording: 'Recording',
  stopRecording: 'Stop Recording',
  saveAndContinue: 'Save & Continue',
  almostDone: 'Almost Done.',
  reviewCarefully: 'Please review your answers carefully and ensure that you are happy with them.',
  reTake: 'Re-take',
  confirmSubmission: 'Confirm Submission',
  submissionReceived: 'Your submission is received.',
  applicationReceived: 'Your application for {roleName} is received',
  setARemainder: 'Set a reminder',
  startRecording: 'Start Recording',
  reRecord: 'Re record',
  errorMessage: {
    responseSaved: 'Your response is saved successfully.',
    unableToSave: 'Unable to save your response. Please record your answer again.',
    interviewCompleted: 'Your video interview is completed.',
    failedToSubmit: 'Failed to submit. Please try again.',
    stopTheRecordBeforeLeave: 'Please stop the recording before you leave this page.',
    unableToSaveResponse: 'Unable to save your response. Please try again.',
    urlCopied: 'URL copied to clipboard successfully!',
    failedToCopy: 'Failed to copy the URL to clipboard.'
  },
  permission: {
    cameraAndMicrophone: 'Allow camera and microphone',
    camera: 'Allow camera',
    microphone: 'Allow microphone'
  },
  copyLinkInstructions: 'Please copy the link and open in mobile browser.(Ex. Chrome, Safari)',
  copyUrl: 'Copy url',
  videoRecordingNotSupported: 'Video recording not supported in this {browserName} browser',
  openInExternalBrowser: 'Open in external browser'
}
export const InterviewArabic = {
  tipsForRecording: 'أفضل النصائح لتسجيل إجاباتك بالفيديو',
  instructions: {
    quietDistractionFree: 'تأكد من أنك في مكان هادئ خالٍ من الانحرافات.',
    checkChargeAndConnection: 'تحقق من أن لديك شحن كافٍ في جهازك واتصال بيانات جيد.',
    practiceToFeelComfortable: 'قم بالتدرب لتشعر بالراحة مع العملية.',
    makeEyeContact: 'حافظ على الاتصال بالعين مع الكاميرا في أقصى حد ممكن.',
    grantAccessToCameraMicrophone: 'تذكر منح الوصول إلى الكاميرا والميكروفون إذا طُلب.'
  },
  navigation: {
    tryItOut: 'جرب ذلك',
    getStarted: 'البدء',
    backToInstructions: 'العودة إلى التعليمات',
    continue: 'متابعة'
  },
  completeToSubmit: 'أكمل مقابلة الفيديو لتقديم طلبك',
  receivedPartially: 'تم استلام طلبك للمنصب {roleName} جزئيًا. أكمل مقابلة الفيديو لتقديم طلبك.',
  ready: 'جاهز',
  recording: 'جار التسجيل',
  stopRecording: 'إيقاف التسجيل',
  saveAndContinue: 'حفظ ومتابعة',
  almostDone: 'تقريبًا انتهى.',
  reviewCarefully: 'يرجى مراجعة إجاباتك بعناية والتأكد من رضاك عنها.',
  reTake: 'إعادة التسجيل',
  confirmSubmission: 'تأكيد التقديم',
  submissionReceived: 'تم استلام تقديمك.',
  applicationReceived: 'تم استلام طلبك للمنصب {roleName}',
  setARemainder: 'تعيين تذكير',
  startRecording: 'بدء التسجيل',
  reRecord: 'إعادة التسجيل',
  errorMessage: {
    responseSaved: 'تم حفظ إجابتك بنجاح.',
    unableToSave: 'تعذر حفظ إجابتك. يرجى تسجيل إجابتك مرة أخرى.',
    interviewCompleted: 'اكتملت مقابلتك بالفيديو.',
    failedToSubmit: 'فشل في التقديم. يرجى المحاولة مرة أخرى.'
  },
  permission: {
    cameraAndMicrophone: 'السماح للكاميرا والميكروفون',
    camera: 'السماح للكاميرا',
    microphone: 'السماح للميكروفون'
  },
  copyLinkInstructions: 'يرجى نسخ الرابط وفتحه في متصفح الهاتف المحمول. (مثل Chrome وSafari)',
  copyUrl: 'نسخ عنوان URL',
  videoRecordingNotSupported: 'تسجيل الفيديو غير مدعوم في هذا المتصفح',
  openInExternalBrowser: 'فتح في متصفح خارجي'
}
