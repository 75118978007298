export const StageModulesEnglish = {
  goBackToStage: 'Go back to {stage}',
  screeningQuestions: 'Screen using questions',
  recommendedQuestions: 'Recommended questions',
  makeItSecret: 'Make it a secret',
  makeItOptional: 'Make it optional',
  writeQuestionHere: 'Write question here...',
  addDescriptionHere: 'Add description here...',
  collectInformation: {
    title: 'Ask questions',
    info: 'Collect more information from the candidates to assist with your hiring'
  },
  automations: {
    stageProgressAutomations: 'Stage progress automations',
    stageProgressAutomationNote: 'Move qualified candidates to the next stage and archive underqualified candidates.',
    usingAutomationOutOf: 'You are using {no} of {total} rejection automations',
    sendRejectionMail: 'Send a rejection email',
    markAsUnresponsive: 'Mark as unresponsive',
    setUpAutomations: 'Setup Automations',
    rejectionAutomations: 'Rejection Automations'
  },
  askAboutTimezone: 'Ask candidates about their current timezone',
  askAboutLocation: 'Ask candidates about their location',
  candidateMustResideTimezone: 'A candidate must be residing within the following time zones',
  candidateMustResideLocation: 'A candidate must be residing within the following region',
  qualifyPeopleOnlyTheyRelocate: 'Qualify people if they are willing to relocate',
  writeCustomQuestion: 'Write a custom question.',
  customQuestionsNote: 'Need help or support with adding a custom question. Get recommendations from our',
  customQuestionGuidelines: 'custom question guidelines',
  inputType: 'Input Type',
  answerType: 'Answer Type',
  noOfDays: 'No of days',
  jobFunction: 'Job Function',
  mustHave: 'Must have:',
  expectedAnswer: 'Expected Answer:',
  options: 'Options',
  addOptionsAndSelectCorrectAns: 'Add options and select the correct answer',
  minimum: 'Minimum',
  maximum: 'Maximum',
  range: 'Range',
  anyOfThem: 'Any of them',
  allOfThem: 'All of them',
  skill: 'Skill',
  industry: 'Industry',
  addOption: 'Add option {no}',
  addDescription: 'Add description',
  removeDescription: 'Remove description',
  howManyYearsOfExperience: 'How many years of experience do you have in {skill}',
  howManyYearsOfExpCurrentlyHave: 'How many years of {job} experience do you currently have? ',
  recommendedScreeningQuestions: 'Recommended screening questions',
  recommendedVideoQuestions: 'Recommended video questions',
  validationErrors: {
    pleaseSelectAnswerType: 'Please select answer type',
    inputTimezone: 'Please input timezone',
    inputLocation: 'Please input location',
    inputCustomQuestion: 'Please input custom question',
    requiredField: 'Required field',
    inputSkill: 'Please input skill',
    inputJobFunction: 'Please input Job function',
    inputIndustry: 'Please input industry',
    enterValidYoutubeUrl: 'Please enter valid & full youtube url',
    unsupportedVideo: 'Your browser does not support the video tag.',
    uploadVideoOrLink: 'Please upload a video or add a YouTube link.',
    jobRoleNotFound: 'Job role not found',
    invalidRoute: 'Invalid Route',
    quizPassPercentage: 'Enter correct quiz pass percentage',
    zeroQuestion: 'Atleast one skill block must have more than zero questions',
    rejectionMailEmpty: 'Rejection email template cannot be empty.',
    archiveUnresponsive: 'Archive Unresponsive After X Days should be greater than or equal 1 Day',
    waitUntilVideoUpload: 'Please wait while video is uploading.',
    pleaseEnterValidInput: 'Please enter a valid input',
    shouldBeLessThanMax: 'Should be less than maximum',
    shouldBeGreaterThanMin: 'Should be greater than minimum',
    pleaseEnterValidNumber: 'Please enter a valid number',
    negativeNotAllowed: 'Negatives not allowed',
    selectCorrectOption: 'Please select correct option',
    pleaseAddOptions: 'Please add more than one options',
    atleastOneOptionToBeSelected: 'Atleast one option need to be selected',
    atleastTwoOptionsNeedToBeAdded: 'Atleast two options need to be added',
    enterProperMinMaxValues: 'Please enter proper minimum, maximum values (minimum < maximum)',
    enterMinValue: 'Please enter proper minimum value',
    enterMaxValue: 'Please enter proper maximum value',
    inputOptionsNotEmpty: 'Options cannot be empty',
    enterQuestionTitle: 'Please enter question title'
  },
  rejectionAutomationsFor: 'Rejection automations for {stage} stage',
  sendRejectionMail: 'Send a rejection email',
  selectRejectionEmailTemplate: 'Select Rejection Email Template',
  sendEmailForArchival: 'Send email for these archival reasons',
  clickToSelectEmailTemplate: 'Click here to select an email template',
  manuallySendRejectionMail: {
    yes: 'Yes, schedule an email that is to be sent after',
    suffix: 'days of getting Rejected',
    no: 'No, I want to manually send Rejection emails to the candidates.'
  },
  markUnresponsive: 'Mark as Unresponsive',
  markUnresponsiveFor: 'Mark them as Unresponsive after',
  templatePreview: 'Template Preview',
  dontMarkUnresponsive: 'Don’t mark them as unresponsive',
  welcomePage: 'Welcome Page',
  welcomePageNote: 'Give candidates a personal welcome experience.',
  addVideo: 'Add Video',
  deleteVideo: 'Delete Video',
  videoQuestions: 'Video Questions',
  duration: 'Duration',
  editsWilBeLost: 'Any edits will be lost.',
  getRecommendations: 'Get recommendations from our',
  videoQuestionsInfo: 'We recommend adding 3 questions. Candidates must respond each question in a video format',
  questions: {
    timeZone: 'What is your current timezone?',
    location: 'What is your current location?',
    yearsOfExperience: 'How many years of [] experience do you currently have?',
    yearsOfExpInSkill: 'How many years of experience do you have in []?',
    expectedSalary: 'What is  your minimum expected salary per month in USD?',
    currentSalary: 'What is your current salary per month in USD?',
    noticePeriod: 'Do you have a notice period with your current employer? If yes, what is the duration in weeks?',
    currentRole: 'What is your current role?',
    currentDepartmentTeam: 'What is your current team (department name)?',
    managerName: 'What is your manager name?',
    employeeId: 'What is your employee ID?',
    videoQuestion: 'Video Question',
    roleInterest: 'Please share more about the role you might be interested in?'
  },
  successMessages: {
    moduleUpdated: 'Module updated successfully'
  },
  areYouSureToCancel: 'Are you sure you want to Cancel?',
  anyEditsWillBeLost: 'Any edits will be lost.',
  continueEditing: 'Continue Editing'
}

export const StageModulesArabic = {
  goBackToStage: 'العودة إلى المرحلة {stage}',
  screeningQuestions: 'فرز باستخدام الأسئلة',
  recommendedQuestions: 'الأسئلة الموصى بها',
  makeItSecret: 'اجعله سرًا',
  makeItOptional: 'اجعلها اختيارية',
  writeQuestionHere: 'اكتب السؤال هنا...',
  addDescriptionHere: 'أضف الوصف هنا...',
  collectInformation: {
    title: 'طرح الأسئلة',
    info: 'اجمع المزيد من المعلومات من المرشحين لمساعدتك في عملية التوظيف'
  },
  automations: {
    stageProgressAutomations: 'التلقائيات لتقدم المراحل',
    stageProgressAutomationNote: 'نقل المرشحين المؤهلين إلى المرحلة التالية وأرشفة المرشحين غير المؤهلين.',
    usingAutomationOutOf: 'أنت تستخدم {no} من إجمالي {total} من التلقائيات للرفض',
    sendRejectionMail: 'إرسال بريد إلكتروني للرفض',
    markAsUnresponsive: 'وضع علامة على عدم الاستجابة',
    setUpAutomations: 'إعداد التلقائيات',
    rejectionAutomations: 'تلقائيات الرفض'
  },
  askAboutTimezone: 'اسأل المرشحين عن التوقيت الحالي لهم',
  askAboutLocation: 'اسأل المرشحين عن موقعهم',
  candidateMustResideTimezone: 'يجب أن يكون المرشح مقيمًا في التوقيتات الزمنية التالية',
  candidateMustResideLocation: 'يجب أن يكون المرشح مقيمًا في المنطقة التالية',
  qualifyPeopleOnlyTheyRelocate: 'قم بتأهيل الأشخاص فقط إذا كانوا على استعداد للانتقال',
  writeCustomQuestion: 'اكتب سؤالًا مخصصًا.',
  customQuestionsNote: 'هل تحتاج إلى مساعدة أو دعم في إضافة سؤال مخصص؟ احصل على توصيات من خلال',
  customQuestionGuidelines: 'إرشادات السؤال المخصص',
  inputType: 'نوع الإدخال',
  answerType: 'نوع الإجابة',
  noOfDays: 'عدد الأيا',
  jobFunction: 'وظيفة العمل',
  mustHave: 'يجب أن يكون لديك:',
  expectedAnswer: 'الإجابة المتوقعة:',
  options: 'خيارات',
  addOptionsAndSelectCorrectAns: 'إضافة خيارات واختيار الإجابة الصحيحة',
  minimum: 'الحد الأدنى',
  maximum: 'الحد الأقصى',
  range: 'النطاق',
  anyOfThem: 'أي منهم',
  allOfThem: 'كلهم',
  skill: 'مهارة',
  industry: 'صناعة',
  addOption: 'أضف خيار {no}',
  addDescription: 'أضف وصفًا',
  removeDescription: 'أزل الوصف',
  howManyYearsOfExperience: 'كم عدد سنوات الخبرة لديك في {skill}',
  howManyYearsOfExpCurrentlyHave: 'كم عدد سنوات الخبرة في {job} لديك حاليًا؟',
  recommendedScreeningQuestions: 'الأسئلة الموصى بها للفحص',
  recommendedVideoQuestions: 'الأسئلة المقترحة للفيديو',
  validationErrors: {
    pleaseSelectAnswerType: 'يرجى اختيار نوع الإجابة',
    inputTimezone: 'يرجى إدخال المنطقة الزمنية',
    inputLocation: 'يرجى إدخال الموقع',
    inputCustomQuestion: 'يرجى إدخال السؤال المخصص',
    requiredField: 'هذا الحقل مطلوب',
    inputSkill: 'يرجى إدخال المهارة',
    inputJobFunction: 'يرجى إدخال وظيفة العمل',
    inputIndustry: 'يرجى إدخال الصناعة',
    enterValidYoutubeUrl: 'الرجاء إدخال عنوان ورابط صحيح لـ YouTube',
    unsupportedVideo: 'متصفحك لا يدعم علامة الفيديو.',
    uploadVideoOrLink: 'الرجاء تحميل فيديو أو إضافة رابط YouTube.',
    jobRoleNotFound: 'لم يتم العثور على دور الوظيفة',
    invalidRoute: 'مسار غير صالح',
    quizPassPercentage: 'يرجى إدخال نسبة نجاح الاختبار الصحيحة',
    zeroQuestion: 'يجب أن يحتوي على الأقل كتلة مهارة واحدة على أكثر من سؤال واحد',
    rejectionMailEmpty: 'لا يمكن أن يكون قالب رسالة الرفض فارغًا.',
    archiveUnresponsive: 'يجب أن يكون "أرشفة غير مستجيب بعد X أيام" أكبر من أو يساوي 1 يوم',
    waitUntilVideoUpload: 'يرجى الانتظار حتى يتم تحميل الفيديو.',
    pleaseEnterValidInput: 'الرجاء إدخال قيمة صحيحة',
    shouldBeLessThanMax: 'يجب أن يكون أقل من الحد الأقصى',
    shouldBeGreaterThanMin: 'يجب أن يكون أكبر من الحد الأدنى',
    pleaseEnterValidNumber: 'الرجاء إدخال رقم صحيح',
    negativeNotAllowed: 'الأرقام السالبة غير مسموح بها',
    selectCorrectOption: 'يرجى اختيار الخيار الصحيح',
    pleaseAddOptions: 'يرجى إضافة أكثر من خيار واحد',
    atleastOneOptionToBeSelected: 'يجب اختيار خيار واحد على الأقل',
    atleastTwoOptionsNeedToBeAdded: 'يجب إضافة خيارين على الأقل',
    enterProperMinMaxValues: 'يرجى إدخال القيم الصحيحة للحد الأدنى والأقصى (الحد الأدنى < الحد الأقصى)',
    enterMinValue: 'يرجى إدخال قيمة الحد الأدنى الصحيحة',
    enterMaxValue: 'يرجى إدخال قيمة الحد الأقصى الصحيحة',
    inputOptionsNotEmpty: 'لا يمكن أن تكون الخيارات فارغة',
    enterQuestionTitle: 'يرجى إدخال عنوان السؤال'
  },
  rejectionAutomationsFor: 'الرفض التلقائي للمرحلة {stage}',
  sendRejectionMail: 'إرسال بريد إلكتروني للرفض',
  selectRejectionEmailTemplate: 'اختيار قالب بريد إلكتروني للرفض',
  sendEmailForArchival: 'إرسال بريد إلكتروني لأسباب الأرشفة هذه',
  clickToSelectEmailTemplate: 'انقر هنا لتحديد قالب بريد إلكتروني',
  manuallySendRejectionMail: {
    yes: 'نعم، جدولة بريد إلكتروني يتم إرساله بعد',
    suffix: 'أيام من الرفض',
    no: 'لا، أريد إرسال بريد إلكتروني للرفض يدويًا إلى المرشحين.'
  },
  markUnresponsive: 'وضع علامة على عدم الاستجابة',
  markUnresponsiveFor: 'وضع علامة عليهم كعدم استجابة بعد',
  templatePreview: 'معاينة القالب',
  dontMarkUnresponsive: 'عدم وضع علامة على عدم الاستجابة',
  welcomePage: 'الصفحة الترحيبية',
  welcomePageNote: 'قدم تجربة ترحيب شخصية للمرشحين.',
  addVideo: 'إضافة فيديو',
  deleteVideo: 'حذف الفيديو',
  videoQuestions: 'أسئلة الفيديو',
  duration: 'المدة',
  continueEditing: 'متابعة التحرير',
  editsWilBeLost: 'سيتم فقدان أي تعديلات.',
  getRecommendations: 'الحصول على توصيات من',
  videoQuestionsInfo: 'نوصي بإضافة 3 أسئلة. يجب على المرشحين الرد على كل سؤال بتنسيق الفيديو',
  questions: {
    timeZone: 'ما هو التوقيت الزمني الحالي لديك؟',
    location: 'ما هو الموقع الحالي الخاص بك؟',
    yearsOfExperience: 'كم عدد سنوات الخبرة التي لديك حاليًا في []؟',
    yearsOfExpInSkill: 'كم عدد سنوات الخبرة لديك في []؟',
    expectedSalary: 'ما هو الحد الأدنى للراتب المتوقع الشهري لديك بالدولار الأمريكي؟',
    currentSalary: 'ما هو الراتب الحالي الخاص بك شهريًا بالدولار الأمريكي؟',
    noticePeriod: 'هل لديك فترة إشعار مع جهة عملك الحالية؟ إذا كان الجواب نعم، ما هي المدة بالأسابيع؟',
    currentRole: 'ما هو دورك الحالي؟',
    currentDepartmentTeam: 'ما هو الفريق الحالي الخاص بك (اسم القسم)؟',
    managerName: 'ما هو اسم مديرك؟',
    employeeId: 'ما هو رقم هوية الموظف الخاص بك؟',
    videoQuestion: 'سؤال فيديو',
    roleInterest: 'يرجى مشاركة المزيد حول الدور الذي قد تكون مهتمًا به؟'
  },
  successMessages: {
    moduleUpdated: 'تم تحديث الوحدة بنجاح'
  }
}
