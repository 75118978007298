import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getUserInfo } from '@/api/auth'
import {
  getCareerName,
  getCompanyId,
  getImpersonatedMode,
  getPermissions,
  getPermissionsV1,
  getRefreshToken,
  getRefreshTokenFromLocal,
  getRoles,
  getSessionEndsIn,
  getToken,
  getTokenFromLocal,
  getTrailPeriodEnded,
  removeCareerName,
  removeCareerNameFromLocal,
  removeCompanyId,
  removeCompanyIdFromLocal,
  removeImpersonatedMode,
  removePermissions,
  removePermissionsFromLocal,
  removeRefreshToken,
  removeRefreshTokenFromLocal,
  removeRoles,
  removeRolesFromLocal,
  removeSessionEndsIn,
  removeToken,
  removeTokenFromLocal,
  setCareerName,
  setCareerNameToLocal,
  setCompanyId,
  setCompanyIdToLocal,
  setImpersonatedMode,
  setPermissions,
  setPermissionsToLocal,
  setRefreshToken,
  setRefreshTokenToLocal,
  setRoles,
  setRolesToLocal,
  setSessionEndsIn,
  setToken,
  setTokenToLocal,
  setTrailPeriodEnded
} from '@/utils/cookies'
import router, { resetRouter } from '@/router'
import { PermissionModule } from './permission'
import store from '@/store'
import CompanyResource from '@/api/company'
import { capsUnderscoreSepToSentence } from '@/utils/general'
import { JobRoleAccessTypeEnum } from '@/views/recruiter/job-role/models/enums'
import { CompanyIntegrationsEnum, WhiteLabelPartnerEnum } from '@/views/dashboard/dashboard-model'
import { RecruitersModule } from '@/store/modules/recruiters'
import { SendEmailsModule } from '@/store/modules/send-emails'
import CompanyRolesResource from '@/api/companyRoles'
import { CompanyModule } from '@/store/modules/company'
import { LanguageEnum } from '@/store/modules/language'
import { PermissionsEnum } from '@/permissions/permissions-enum'
import { storeToExtension } from '@/utils/extension-storage'

export interface IJobCollaboarationPermission {
  jobRoleId: string
  hasEdit: boolean
  hasSecretNotes: boolean
  hasOffer: boolean
  hasStageMoveAndSendEmail: boolean
  hasAllStages: boolean
  selectedStages: string[]
  permissions: string[]
}

export interface IUserState {
  token: string
  name: string
  avatar: string
  introduction: string
  roles: string[]
  permissions: string[]
  permissionV1: PermissionsEnum[]
  email: string
  companyId: string
  jobCollaborationPermissions: IJobCollaboarationPermission[]
}

@Module({ dynamic: true, store, name: 'user', namespaced: true })
class User extends VuexModule implements IUserState {
  public token = getToken() || getTokenFromLocal() || ''
  public refreshToken = getRefreshToken() || getRefreshTokenFromLocal() || ''
  public name = ''
  public firstName = ''
  public lastName = ''
  public id = ''
  public avatar = ''
  public introduction = ''
  public roles = getRoles() && JSON.parse(decodeURIComponent(getRoles() || '[]')).length ? JSON.parse(decodeURIComponent(getRoles() || '[]')) : [] as string[]
  public permissions = getPermissions() && JSON.parse(decodeURIComponent(getPermissions() || '[]')).length ? JSON.parse(decodeURIComponent(getPermissions() || '[]')) : [] as string[]
  public permissionV1 = getPermissionsV1() && JSON.parse(decodeURIComponent(getPermissionsV1() || '[]')).length ? JSON.parse(decodeURIComponent(getPermissionsV1() || '[]')) : [] as string[]
  public email = ''
  public companyId = getCompanyId() || ''
  public careerName = getCareerName() || ''
  public access = ''
  public companyName = ''
  public companyDomain = ''
  public companyIntegrationType = ''
  public whiteLabelPartner = ''
  public whiteLabelName = ''
  public isAtsCompany = false
  public userIntegrations: Array<{
    integrationType: string
  }> = []

  public companyLanguage = LanguageEnum.ENGLISH

  public jobCollaborationPermissions: IJobCollaboarationPermission[] = []
  public jobCollaborationPermissionsV1: IJobCollaboarationPermission[] = []
  public trailPeriodEnded = getTrailPeriodEnded() || false
  public showExploreProductCard = false
  public impersonatedMode = getImpersonatedMode() ? getImpersonatedMode() === 'true' : false
  public sessionEndsIn = getSessionEndsIn() || ''
  public agencyUserId = ''

  @Mutation
  private SET_IMPERSONATED_MODE(mode: boolean) {
    this.impersonatedMode = mode
  }

  @Mutation
  private SET_SESSION_ENDS_IN(time: string) {
    this.sessionEndsIn = time
  }

  @Mutation
  private SET_COMPANY_LANGUAGE(language: LanguageEnum) {
    this.companyLanguage = language
  }

  @Mutation
  private SET_SHOW_EXPLORE_PRODUCT_CARD(show: boolean) {
    this.showExploreProductCard = show
  }

  @Mutation
  private SET_JOB_COLLABORATION_PERMISSIONS(jobCollaborationPermissions: IJobCollaboarationPermission[]) {
    this.jobCollaborationPermissions = jobCollaborationPermissions
  }

  @Mutation
  private SET_JOB_COLLABORATION_PERMISSIONSV1(jobCollaborationPermissionsV1: IJobCollaboarationPermission[]) {
    this.jobCollaborationPermissionsV1 = jobCollaborationPermissionsV1
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_REFRESH_TOKEN(token: string) {
    this.refreshToken = token
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_FIRST_NAME(firstName: string) {
    this.firstName = firstName
  }

  @Mutation
  private SET_COMPANY_INTEGRATION_TYPE(type: CompanyIntegrationsEnum) {
    this.companyIntegrationType = type
  }

  @Mutation
  private SET_LAST_NAME(lastName: string) {
    this.lastName = lastName
  }

  @Mutation
  private SET_ID(id: string) {
    this.id = id
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction
  }

  @Mutation
  private SET_CAREER_NAME(careerName: string) {
    this.careerName = careerName
  }

  @Mutation
  private SET_COMPANY_NAME(companyName: string) {
    this.companyName = companyName
  }

  @Mutation
  private SET_COMPANY_DOMAIN(domain: string) {
    this.companyDomain = domain
  }

  @Mutation
  private SET_WHITE_LABEL_PARTNER(whiteLabelPartner: string) {
    this.whiteLabelPartner = whiteLabelPartner
  }

  @Mutation
  private SET_WHITE_LABEL_NAME(whiteLabelPartner: string) {
    if (whiteLabelPartner) {
      this.whiteLabelName = capsUnderscoreSepToSentence(whiteLabelPartner)
    }
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
    const admin = this.roles.some((item) => item === 'COMPANY_ADMIN')
    const interviewer = this.roles.some((item) => item === 'COMPANY_INTERVIEWER')
    const candidate = this.roles.some((item) => item === 'CANDIDATE')
    if (admin) {
      this.access = JobRoleAccessTypeEnum.FULL_ACCESS
    } else if (interviewer) {
      this.access = JobRoleAccessTypeEnum.LIMITED_ACCESS
    } else if (candidate) {
      this.access = JobRoleAccessTypeEnum.CANDIDATE
    } else {
      this.access = JobRoleAccessTypeEnum.EMPLOYEE
    }
  }

  @Mutation
  private SET_PERMISSIONS(permissions: string[]) {
    this.permissions = permissions
  }

  @Mutation
  private SET_PERMISSIONS_V1(permissionV1: string[]) {
    this.permissionV1 = permissionV1
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email
  }

  @Mutation
  private SET_ATS_COMPANY(isAtsCompany: boolean) {
    this.isAtsCompany = isAtsCompany
  }

  @Mutation
  private SET_COMPANYID(companyID: string) {
    this.companyId = companyID
  }

  @Mutation
  private SET_USER_INTEGRATIONS(userIntegrations: Array<{
    integrationType: string
  }>) {
    this.userIntegrations = userIntegrations
  }

  @Action
  public async UpdateTokenAfterLogin(data: { status: boolean, token: { accessToken: string, refreshToken: string }, devRevSessionToken: string }) {
    if (data.status) {
      storeToExtension('token', data.token.accessToken)
      storeToExtension('refreshToken', data.token.refreshToken)
      setToken(data.token.accessToken)
      setTokenToLocal(data.token.accessToken)
      setRefreshToken(data.token.refreshToken)
      setRefreshTokenToLocal(data.token.refreshToken)
      this.context.commit('SET_TOKEN', data.token.accessToken)
      this.context.commit('SET_REFRESH_TOKEN', data.token.refreshToken)
    }
  }

  @Action
  public SetImpersonatedMode(mode: boolean) {
    this.SET_IMPERSONATED_MODE(mode)
    setImpersonatedMode(mode ? 'true' : 'false')
  }

  @Action
  public SetSessionEndsIn(time: string) {
    this.SET_SESSION_ENDS_IN(time)
    setSessionEndsIn(time)
  }

  @Action
  public SetTokenForCandidate(token: string) {
    setToken(token)
    setTokenToLocal(token)
    this.SET_TOKEN(token)
  }

  @Action
  public SetRefreshToken(token: string) {
    setRefreshToken(token)
    setRefreshTokenToLocal(token)
    this.SET_REFRESH_TOKEN(token)
  }

  @Action
  public ResetToken() {
    removeToken()
    removeTokenFromLocal()
    removePermissionsFromLocal()
    removeCompanyIdFromLocal()
    removeRoles()
    removePermissions()
    removeCompanyId()
    removeRefreshTokenFromLocal()
    removeRefreshToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
    this.SET_COMPANYID('')
  }

  @Action
  public SetExploreProductCard(show: boolean) {
    this.SET_SHOW_EXPLORE_PRODUCT_CARD(show)
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }
    const { data } = await getUserInfo()
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }

    const { id, roles, permissions, permissionV1, firstName, lastName, avatar, introduction, email, companyId, collaboratorPermissions, collaboratorPermissionsV1, userIntegrations } = data
    // roles must be a non-empty array
    if (!roles || roles.length <= 0) {
      throw Error('GetUserInfo: roles must be a non-null array!')
    }
    const fullName = `${firstName} ${lastName}`.trim()
    this.SET_ID(id)
    this.SET_ROLES(roles)
    setRoles(JSON.stringify(roles))
    setRolesToLocal(JSON.stringify(roles))
    setPermissions(JSON.stringify(permissions))
    setPermissionsToLocal(JSON.stringify(permissions))
    this.SET_PERMISSIONS(permissions)
    this.SET_PERMISSIONS_V1(permissionV1)
    this.SET_NAME(fullName)
    this.SET_FIRST_NAME(firstName)
    this.SET_LAST_NAME(lastName)
    this.SET_AVATAR(avatar)
    this.SET_INTRODUCTION(introduction)
    this.SET_EMAIL(email)
    setCompanyId(companyId)
    setCompanyIdToLocal(companyId)
    this.SET_COMPANYID(companyId)
    this.SET_USER_INTEGRATIONS(userIntegrations)
    this.SET_JOB_COLLABORATION_PERMISSIONS(collaboratorPermissions)
    this.SET_JOB_COLLABORATION_PERMISSIONSV1(collaboratorPermissionsV1)
    if (companyId) {
      const company = (await (new CompanyResource()).getCompany(companyId)).data
      try {
        if (this.access === JobRoleAccessTypeEnum.FULL_ACCESS) {
          const exploreProduct = (await (new CompanyRolesResource()).showExploreProductCard(companyId)).data as boolean
          this.SET_SHOW_EXPLORE_PRODUCT_CARD(exploreProduct)
        }
      } catch (e) {
        //
      }
      setTrailPeriodEnded(`${company.isTrailEnded}`)
      setCareerName(company.careerName)
      setCareerNameToLocal(company.careerName)
      this.SET_ATS_COMPANY(company.isAtsCompany)
      this.SET_CAREER_NAME(company.careerName)
      this.SET_COMPANY_NAME(company.name)
      this.SET_COMPANY_DOMAIN(company.emailDomain)
      this.SET_COMPANY_INTEGRATION_TYPE(company.integrationType)
      this.SET_WHITE_LABEL_PARTNER(company.whiteLabelPartner || WhiteLabelPartnerEnum.WC)
      this.SET_WHITE_LABEL_NAME(company.whiteLabelPartner || WhiteLabelPartnerEnum.WC)
      this.SET_COMPANY_LANGUAGE(company.language)
      await RecruitersModule.setRecruiters(true)
      await SendEmailsModule.setCompanyEmails(true)
      await SendEmailsModule.setBookingLinks(true)
      await CompanyModule.setLanguageSupport(companyId)
      await CompanyModule.enableLinkedinIntegration(company.careerName)
      await CompanyModule.setUpLinkedinIntegration({
        linkedinCompanyId: company.linkedinCompanyId,
        isLinkedinIntegrationActive: company.isLinkedinIntegrationActive,
        linkedinIntegrationType: company.linkedinIntegrationType
      })
      CompanyModule.setHrmsIntegrationType(company.hrmsIntegrationType)
    }
    return data
  }

  @Action
  public async ChangeRoles(role: string) {
    // Dynamically modify permissions
    const token = role + '-token'
    this.SET_TOKEN(token)
    setToken(token)
    setTokenToLocal(token)
    await this.GetUserInfo()
    resetRouter()
    // Generate dynamic accessible routes based on roles
    PermissionModule.GenerateRoutes()
    // Add generated routes
    PermissionModule.routes.forEach(route => {
      router.addRoute(route)
    })
  }

  @Action
  public async setIntegrations(userIntegrations) {
    this.SET_USER_INTEGRATIONS(userIntegrations)
  }

  @Mutation
  private SET_AGENCY_USER_ID(agencyUserId: string) {
    this.agencyUserId = agencyUserId
  }

  @Action
  public async setAgencyUserId(agencyUserId: string) {
    this.SET_AGENCY_USER_ID(agencyUserId)
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    storeToExtension('token', '')
    storeToExtension('refreshToken', '')
    removeToken()
    removeRefreshToken()
    removeRoles()
    removePermissions()
    removeCompanyId()
    resetRouter()
    removeCareerName()
    removeCompanyIdFromLocal()
    removePermissionsFromLocal()
    removeRolesFromLocal()
    removeTokenFromLocal()
    removeRefreshTokenFromLocal()
    removeCareerNameFromLocal()
    removeImpersonatedMode()
    removeSessionEndsIn()
    // Reset visited views and cached views
    this.SET_TOKEN('')
    this.SET_REFRESH_TOKEN('')
    this.SET_COMPANYID('')
    this.SET_ROLES([])
    this.SET_ID('')
    this.SET_PERMISSIONS([])
    this.SET_PERMISSIONS_V1([])
    this.SET_IMPERSONATED_MODE(false)
    this.SET_SESSION_ENDS_IN('')
  }

  get agencyUserIdData(): string {
    return this.agencyUserId
  }
}

export const UserModule = getModule(User)
