export const HomePageEnglish = {
  welcome: 'Welcome, {name} 👋',
  myCandidates: 'My Candidates',
  myJobs: 'My jobs',
  title: 'Home',
  interviews: 'Interviews',
  pendingScore: 'Pending feedback',
  today: 'Today',
  getSupport: 'Get support',
  externalJobs: 'External jobs',
  internalJobs: 'Internal jobs',
  tooltips: {
    candidatesYouOwn: 'Candidates you own',
    jobsYouOwn: 'Jobs you own',
    publishedJobsOnCareerPage: 'Published jobs on career page',
    publishedJobsOnEmployeePortals: 'Published jobs on employee portal'
  },
  checklist: {
    title: 'Implementation checklist',
    readMore: 'Click here to read more',
    completedText: 'Completed ({count} items)',
    countOf: '{count} of {total}',
    subTitle: 'Complete your checklist to get the most out of your recruitment software.',
    everyThingSetup: 'All setup is complete',
    clickHere: 'Click here to know about {content}',
    companyBrand: {
      name: 'company branding',
      title: 'Set up your company brand',
      description: 'Add your logo and brand color to show your branding on the candidate portal.'
    },
    careerPage: {
      name: 'career page',
      title: 'Customise your career page',
      description: 'Add a banner, company description or video on career page to get your candidates excited.'
    },
    locationDepartments: {
      name: 'locations and departments',
      title: 'Add locations and departments',
      description: 'Add company locations and departments to streamline job creation and keep jobs organized.'
    },
    publishJob: {
      name: 'publishing job',
      title: 'Publish a job',
      description: 'Publish your company\'s first job to start hiring.'
    },
    jobBoards: {
      name: 'job boards',
      title: 'Connect a job with job board (e.g. linkedin)',
      description: 'Connect with a job board like linkedin to advertise your job and track candidate sources.'
    },
    emailTemplate: {
      name: 'email templates',
      title: 'Create a new email template',
      description: 'Create email templates to save time while sending email to candidates.'
    },
    bookingLink: {
      name: 'booking links',
      title: 'Add an interview booking link',
      description: 'Create a booking link to let candidates book interviews directly in free slots of your calendar.'
    },
    interviewScorecard: {
      name: 'interview feedback templates',
      title: 'Add an interview feedback',
      description: 'Create interview feedback template to add skill-based questions and evaluate candidate after the interview.'
    },
    offerTemplate: {
      name: 'offer templates',
      title: 'Add an offer letter template',
      description: 'Create offer templates to generate and send your offer letters in a few clicks.'
    },
    integrateEmail: {
      name: 'integration of mail',
      title: 'Integrate your email',
      description: 'Integrate your email to send emails from your email address and sync candidate replies.'
    },
    integrateCalender: {
      name: 'integration of calendar',
      title: 'Integrate your calendar',
      description: 'Integrate your calendar to schedule interviews or ask candidates to book the slot.'
    },
    sendMail: {
      name: 'sending mail',
      title: 'Send an email to a candidate',
      description: 'Send an email to a candidate.'
    },
    addNotes: {
      name: 'adding notes',
      title: 'Add a notes on a candidate profile',
      description: 'Add a note and use "@" to mention any team member.'
    },
    scheduleInterview: {
      title: 'Schedule an interview with a candidate',
      name: 'scheduling an interview with a candidate'
    }
  },
  watchlist: {
    title: 'Stage watchlist',
    info: 'Watch active candidates in a stage',
    addToWatchlist: 'Add to watchlist',
    nothingInWatchlist: 'Nothing in watchlist',
    addMultipleStagesInfo: 'Add multiple stages that you want to keep an eye on.',
    chooseJob: 'Choose job',
    selectStage: 'Select stage',
    validationErrors: {
      selectJob: 'Please select job role',
      selectStage: 'Please select stage'
    },
    successMessages: {
      createdWatchlist: 'Successfully added to watchlist',
      deletedWatchlist: 'Watchlist item deleted successfully'
    }
  },
  jobsWatchlist: {
    title: 'Jobs watchlist',
    jobs: 'Jobs',
    addToWatchlist: 'Add to watchlist',
    nothingInWatchlist: 'Nothing in watchlist',
    addMultipleJobsInfo: 'Add multiple jobs that you want to keep an eye on.',
    activeCandidates: 'Active Candidates',
    enteredStageInLast: 'Entered stage in last',
    chooseJob: 'Choose job',
    showAll: 'Show all',
    validationErrors: {
      selectJob: 'Please select job role'
    },
    successMessages: {
      createdWatchlist: 'Successfully added to watchlist',
      deletedWatchlist: 'Watchlist item deleted successfully'
    }
  },
  inbox: {
    title: 'Inbox',
    notifications: 'Notifications',
    display: 'Display',
    ordering: 'Ordering',
    newest: 'Newest',
    oldest: 'Oldest',
    unread: 'Unread',
    all: 'All',
    starred: 'Starred',
    bulkActions: 'Bulk actions',
    preferences: 'Preferences',
    sort: 'Sort',
    markAllAsRead: 'Mark all as read',
    removeStarred: 'Remove starred',
    markAsStarred: 'Mark as starred',
    markAsRead: 'Mark as read',
    markAsUnread: 'Mark as unread',
    markAllAsUnread: 'Mark all as unread',
    deleteAllNotifications: 'Delete all notifications',
    deleteAllReadNotifications: 'Delete all read notifications',
    showOnlyUnread: 'Show only unread',
    notificationPreferences: 'Notification preferences',
    emptyAll: 'No new notifications',
    emptyUnread: 'No unread notifications',
    emptyStarred: 'No starred notifications',
    emptyInboxDescription: 'There are no notifications at the moment',
    emptyUnreadDescription: 'There are no unread notifications at the moment',
    emptyStarredDescription: 'There are no starred notifications at the moment',
    confirmationToDeleteAllReadNotifications: 'Are you sure you want to delete all read notifications?',
    confirmSingleNotificationDelete: 'Are you sure you want to delete notification?',
    confirmationToDeleteAllNotifications: 'Are you sure you want to delete all notifications?',
    successMessages: {
      deletedAllReadNotifications: 'Successfully deleted all read notifications',
      deletedAllNotifications: 'Successfully deleted all notifications',
      deleteNotification: 'Notification deleted successfully'
    },
    uptoDate: 'You’re up-to-date!'
  }
}

export const HomePageArabic = {
  welcome: 'أهلاً وسهلاً، {name} 👋',
  myCandidates: 'مرشحوي',
  myJobs: 'وظائفي',
  title: 'الصفحة الرئيسية',
  interviews: 'المقابلات',
  pendingScore: 'في انتظار التعليقات',
  today: 'اليوم',
  getSupport: 'الحصول على الدعم',
  externalJobs: 'وظائف خارجية',
  internalJobs: 'وظائف داخلية',
  tooltips: {
    candidatesYouOwn: 'المرشحون الذين تمتلكهم',
    jobsYouOwn: 'الوظائف التي تمتلكها',
    publishedJobsOnCareerPage: 'الوظائف المنشورة على صفحة الوظائف',
    publishedJobsOnEmployeePortals: 'الوظائف المنشورة على بوابة الموظفين'
  },
  checklist: {
    title: 'قائمة التنفيذ',
    readMore: 'انقر هنا لقراءة المزيد',
    completedText: 'تم الانتهاء ({count} عناصر)',
    countOf: '{count} من {total}',
    subTitle: 'أكمل قائمتك لتحصل على أقصى استفادة من برنامج التوظيف الخاص بك.',
    everyThingSetup: 'تم إعداد كل شيء',
    clickHere: 'انقر هنا لمعرفة المزيد حول {content}',
    companyBrand: {
      name: 'توثيق الشركة',
      title: 'قم بإعداد علامة شركتك',
      description: 'أضف شعارك ولون علامتك التجارية لعرض علامتك التجارية على بوابة المرشحين.'
    },
    careerPage: {
      name: 'صفحة التوظيف',
      title: 'قم بتخصيص صفحة التوظيف الخاصة بك',
      description: 'أضف لافتة إعلانية أو وصف للشركة أو فيديو على صفحة التوظيف لإثارة حماس المرشحين.'
    },
    locationDepartments: {
      name: 'المواقع والأقسام',
      title: 'إضافة المواقع والأقسام',
      description: 'أضف مواقع الشركة والأقسام لتبسيط إنشاء الوظائف والحفاظ على تنظيم الوظائف.'
    },
    publishJob: {
      name: 'نشر الوظيفة',
      title: 'نشر وظيفة',
      description: 'انشر أول وظيفة لشركتك لبدء عملية التوظيف.'
    },
    jobBoards: {
      name: 'لوحات الوظائف',
      title: 'قم بربط وظيفة بلوحة الوظائف (مثل LinkedIn)',
      description: 'اتصل بلوحة وظائف مثل LinkedIn للإعلان عن وظيفتك وتتبع مصادر المرشحين.'
    },
    emailTemplate: {
      name: 'قوالب البريد الإلكتروني',
      title: 'إنشاء قالب بريد إلكتروني جديد',
      description: 'إنشاء قوالب بريد إلكتروني لتوفير الوقت أثناء إرسال البريد الإلكتروني إلى المرشحين.'
    },
    bookingLink: {
      name: 'روابط الحجوزات',
      title: 'إضافة رابط حجز مقابلة',
      description: 'أنشئ رابط حجز للسماح للمرشحين بحجز المقابلات مباشرة في الفترات الفارغة في جدولك الزمني.'
    },
    interviewScorecard: {
      name: 'قوالب ردود الفعل المقابلة',
      title: 'إضافة تعليقات المقابلة',
      description: 'قم بإنشاء قالب تعليقات المقابلة لإضافة أسئلة قائمة على المهارات وتقييم المرشح بعد المقابلة.'
    },
    offerTemplate: {
      name: 'قوالب العروض',
      title: 'إضافة قالب لخطاب العرض',
      description: 'إنشاء قوالب العروض لإنشاء وإرسال خطابات العروض الخاصة بك ببضع نقرات فقط.'
    },
    integrateEmail: {
      name: 'دمج البريد الإلكتروني',
      title: 'دمج بريدك الإلكتروني',
      description: 'دمج بريدك الإلكتروني لإرسال رسائل البريد الإلكتروني من عنوان بريدك الإلكتروني ومزامنة ردود المرشحين.'
    },
    integrateCalender: {
      name: 'دمج التقويم',
      title: 'دمج تقويمك',
      description: 'دمج تقويمك لجدولة المقابلات أو طلب المرشحين حجز الفتحة.'
    },
    sendMail: {
      name: 'إرسال البريد',
      title: 'إرسال بريد إلكتروني إلى مرشح',
      description: 'إرسال بريد إلكتروني إلى مرشح.'
    },
    addNotes: {
      name: 'إضافة الملاحظات',
      title: 'أضف ملاحظة على ملف مرشح',
      description: 'أضف ملاحظة واستخدم "@" للإشارة إلى أي عضو في الفريق.'
    },
    scheduleInterview: {
      title: 'جدولة مقابلة مع مرشح',
      name: 'جدولة مقابلة مع مرشح'
    }
  },
  watchlist: {
    title: 'قائمة المراقبة',
    info: 'مراقبة المرشحين النشطين في مرحلة معينة',
    addToWatchlist: 'إضافة إلى قائمة المراقبة',
    nothingInWatchlist: 'لا يوجد شيء في قائمة المراقبة',
    addMultipleStagesInfo: 'أضف مراحل متعددة ترغب في متابعتها.',
    chooseJob: 'اختر وظيفة',
    selectStage: 'اختر مرحلة',
    validationErrors: {
      selectJob: 'يرجى اختيار دور الوظيفة',
      selectStage: 'يرجى اختيار المرحلة'
    },
    successMessages: {
      createdWatchlist: 'تمت إضافته بنجاح إلى قائمة المراقبة',
      deletedWatchlist: 'تم حذف عنصر قائمة المراقبة بنجاح'
    }
  },
  jobsWatchlist: {
    title: 'قائمة مراقبة الوظائف',
    jobs: 'الوظائف',
    addToWatchlist: 'أضف إلى قائمة المراقبة',
    nothingInWatchlist: 'لا شيء في قائمة المراقبة',
    addMultipleJobsInfo: 'أضف وظائف متعددة تريد متابعة حالتها.',
    activeCandidates: 'المرشحون النشطون',
    enteredStageInLast: 'دخل المرحلة في الآونة الأخيرة',
    chooseJob: 'اختر وظيفة',
    showAll: 'عرض الكل',
    validationErrors: {
      selectJob: 'يرجى تحديد دور الوظيفة'
    },
    successMessages: {
      createdWatchlist: 'تمت إضافته بنجاح إلى قائمة المراقبة',
      deletedWatchlist: 'تم حذف عنصر قائمة المراقبة بنجاح'
    }
  },
  inbox: {
    title: 'البريد الوارد',
    notifications: 'الإشعارات',
    display: 'عرض',
    ordering: 'ترتيب',
    newest: 'الأحدث',
    oldest: 'الأقدم',
    unread: 'غير مقروء',
    all: 'الكل',
    starred: 'المميز',
    bulkActions: 'إجراءات جماعية',
    preferences: 'التفضيلات',
    sort: 'ترتيب',
    markAllAsRead: 'وضع علامة "مقروء" على الكل',
    removeStarred: 'إزالة المميز',
    markAsStarred: 'وضع علامة كمميز',
    markAsRead: 'وضع علامة كمقروء',
    markAsUnread: 'وضع علامة كغير مقروء',
    markAllAsUnread: 'وضع علامة "غير مقروء" على الكل',
    deleteAllNotifications: 'حذف جميع الإشعارات',
    deleteAllReadNotifications: 'حذف جميع الإشعارات المقروءة',
    showOnlyUnread: 'عرض الغير مقروء فقط',
    notificationPreferences: 'تفضيلات الإشعارات',
    emptyAll: 'لا توجد إشعارات جديدة',
    emptyUnread: 'لا توجد إشعارات غير مقروءة',
    emptyStarred: 'لا توجد إشعارات مميزة',
    emptyInboxDescription: 'لا توجد إشعارات في الوقت الحالي',
    emptyUnreadDescription: 'لا توجد إشعارات غير مقروءة في الوقت الحالي',
    emptyStarredDescription: 'لا توجد إشعارات مميزة في الوقت الحالي',
    confirmationToDeleteAllReadNotifications: 'هل أنت متأكد أنك تريد حذف جميع الإشعارات المقروءة؟',
    confirmSingleNotificationDelete: 'هل أنت متأكد أنك تريد حذف الإشعار؟',
    confirmationToDeleteAllNotifications: 'هل أنت متأكد أنك تريد حذف جميع الإشعارات؟',
    successMessages: {
      deletedAllReadNotifications: 'تم حذف جميع الإشعارات المقروءة بنجاح',
      deletedAllNotifications: 'تم حذف جميع الإشعارات بنجاح'
    },
    uptoDate: 'أنت مُحدث بالكامل!'
  }
}
