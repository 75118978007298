import Resource from '@/api/resource'
import { InboxEntity } from '@/views/home-page/inbox/models/inbox.entity'
import request from '@/utils/request'
import { InboxFilterEnum } from '@/views/home-page/inbox/models/inbox-type.enum'

class InboxNotificationsResource extends Resource<InboxEntity> {
  constructor() {
    super('inbox')
  }

  deleteNotifications(isRead: boolean) {
    return request({
      url: '/' + this.uri + `/actions/delete?isRead=${isRead}`,
      method: 'delete'
    })
  }

  async listCount(filter: InboxFilterEnum): Promise<number> {
    return request({
      url: '/' + this.uri + `/list-count?filter=${filter}`,
      method: 'get'
    }).then((res) => res.data as number)
  }
}

export { InboxNotificationsResource as default }
