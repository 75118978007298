
import { UserModule } from '@/store/modules/user'
import RecruiterNavTab from '@/components/RecruiterNavbar/RecruiterNavTab.vue'
import { Component, Vue } from 'vue-property-decorator'
import CompanyLogo from '@/views/logos/company.logo.vue'
import PseudoNotificationComponent from '@/components/PseudoNotificationComponent.vue'
import { JobRoleAccessTypeEnum } from '@/views/recruiter/job-role/models/enums'
import RecruiterNavbarDropdownMenu from '@/components/RecruiterNavbar/RecruiterNavbarDropdownMenu.vue'
import { LanguageEnum, LanguageModule } from '@/store/modules/language'
import { chromeIcon, helpCenterIcon, settingsIcon } from '@/components/RecruiterNavbar/svg.icons'
import LanguageSwitcher from '@/components/UILibrary/LanguageSwitcher.vue'
import { CompanyModule } from '@/store/modules/company'
import WcDialog from '@/components/UILibrary/WcDialog.vue'
import WcTypography from '@/components/UILibrary/Typography/WcTypography.vue'
import ChatBotDialog from '@/components/Chatbot/ChatBotDialog.vue'
import Inbox from '@/views/home-page/inbox/Inbox.vue'
import { PermissionsEnum } from '@/permissions/permissions-enum'

@Component({
  name: 'RecruiterNav',
  computed: {
    LanguageEnum() {
      return LanguageEnum
    },
    getCurrentLanguage() {
      return LanguageModule.language
    },
    JobRoleAccessTypeEnum() {
      return JobRoleAccessTypeEnum
    },
    showLanguageSupport() {
      return CompanyModule.haveLanguageSupport
    }
  },
  methods: {
    settingsIcon() {
      return settingsIcon
    },
    helpCenterIcon() {
      return helpCenterIcon
    },
    chromeIcon() {
      return chromeIcon
    },
    LanguageModule() {
      return LanguageModule
    },
    UserModule() {
      return UserModule
    }
  },
  props: {
    user: Object
  },
  components: {
    Inbox,
    ChatBotDialog,
    WcTypography,
    WcDialog,
    LanguageSwitcher,
    RecruiterNavbarDropdownMenu,
    PseudoNotificationComponent,
    CompanyLogo,
    RecruiterNavTab
  }
})
export default class extends Vue {
  private companyId = '';
  isVisible = false;
  mounted(): void {
    this.initNavbar()
  }

  initNavbar(): void {
    this.companyId = UserModule.companyId || ''
  }

  handleBot(): void {
    this.isVisible = true
  }

  get hasAccessToReports() {
    return [PermissionsEnum.COMPANY_SUPERSET_REPORTS]
  }
}
