import { RouteConfig } from 'vue-router'
import {
  CAREER_BRAND_PAGE_PERMISSIONS,
  COMPANY_AGENCY_MANAGE_PERMISSIONS,
  COMPANY_BOOKING_LINKS_MANAGE, COMPANY_CALENDAR_EMAIL_INTEGRATIONS_MANAGE,
  COMPANY_EMAIL_TEMPLATES_MANAGE,
  COMPANY_HRMS_INTEGRATIONS_MANAGE,
  COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_MANAGE,
  COMPANY_LOCATIONS_DEPARTMENTS_MANAGE,
  COMPANY_OFFER_LETTER_TEMPLATE_MANAGE,
  COMPANY_TEAM_MEMBERS_MANAGE,
  STAGE_PIPELINE_ARCHIVAL_REASON_PERMISSIONS,
  USER_NOTIFICATIONS_MANAGE
} from '@/permissions/permissions-constants'
import { RolesEnum } from '@/views/recruiter/models/roles-model'
import { PermissionsEnum } from '@/permissions/permissions-enum'
import { checkRole } from '@/store/modules/permission'

export const RecruiterSettingsRoutes: RouteConfig[] = [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      if (checkRole(RolesEnum.COMPANY_ADMIN)) {
        next('/recruiter-settings/brands')
      } else if (checkRole(RolesEnum.COMPANY_INTERVIEWER)) {
        next('/recruiter-settings/interview-booking')
      } else if (checkRole(RolesEnum.COMPANY_EMPLOYEE)) {
        next('/recruiter-settings/profile')
      } else {
        next('/404')
      }
    }
  },
  {
    path: 'brands',
    alias: 'career',
    component: () => import(/* webpackChunkName: "companyBranding" */ '@/views/company-branding/CompanyBranding.vue'),
    meta: {
      title: 'Company Configuration',
      permissions: [...CAREER_BRAND_PAGE_PERMISSIONS],
      requiredAllPermissions: false // atleast one permission enough to access route
    }
  },
  {
    path: 'manage-emails',
    component: () => import(/* webpackChunkName: "manageEmails" */ '@/views/recruiter/manage-email-templates/ManageEmailTemplates.vue'),
    meta: {
      title: 'Manage Email Templates',
      hidden: true,
      permissions: [...COMPANY_EMAIL_TEMPLATES_MANAGE]
    }
  },
  {
    path: 'teams',
    component: () => import(/* webpackChunkName: "connectTeams" */ '@/views/recruiter/teams/Teams.vue'),
    meta: {
      title: 'Teams',
      hidden: true,
      permissions: [...COMPANY_TEAM_MEMBERS_MANAGE],
      requiredAllPermissions: false // atleast one permission enough to access route
    }
  },
  {
    path: 'stage-pipeline',
    component: () => import(/* webpackChunkName: "stagePipeline" */ '@/views/stage-pipeline/StagePipeline.vue'),
    meta: {
      title: 'Stage Pipeline',
      permissions: [...STAGE_PIPELINE_ARCHIVAL_REASON_PERMISSIONS],
      requiredAllPermissions: false
    }
  },
  {
    path: 'profile',
    component: () => import(/* webpackChunkName: "profileIndex1" */ '@/views/profile/user-profile.vue'),
    meta: {
      hidden: true,
      title: 'Profile',
      permissions: [PermissionsEnum.COMPANY_SELF_PROFILE]
    }
  },
  {
    path: 'app-integration',
    component: () => import(/* webpackChunkName: "appIntegrations" */ '@/views/integrations/integrations.vue'),
    meta: {
      hidden: true,
      title: 'Calendar',
      permissions: [...COMPANY_CALENDAR_EMAIL_INTEGRATIONS_MANAGE]
    }
  },
  {
    path: 'interview-booking',
    component: () => import(/* webpackChunkName: "interviewBooking" */ '@/views/recruiter/booking-links/InterviewBooking.vue'),
    meta: {
      hidden: true,
      title: 'Interview Booking',
      icon: 'dashboard',
      permissions: [...COMPANY_BOOKING_LINKS_MANAGE]
    }
  },
  {
    path: 'agencies',
    component: () => import(/* webpackChunkName: "agenciesSettings" */ '@/views/agencies/Agencies.vue'),
    meta: {
      hidden: true,
      title: 'Agencies',
      permissions: [...COMPANY_AGENCY_MANAGE_PERMISSIONS],
      requiredAllPermissions: true
    }
  },
  {
    path: 'offers',
    component: () => import(/* webpackChunkName: "settingsOffers" */ '@/views/offers/ListOffers.vue'),
    meta: {
      hidden: true,
      title: 'Offers',
      permissions: [...COMPANY_OFFER_LETTER_TEMPLATE_MANAGE],
      requiredAllPermissions: true
    }
  },
  {
    path: 'company-integrations',
    alias: 'hrms-integration', // Bayzat is still using the old route TODO: remove this after bayzat updates
    component: () => import(/* webpackChunkName: "hrmsIntegration" */ '@/views/settings/integrations/CompanyIntegrations.vue'),
    meta: {
      hidden: true,
      title: 'HRMS Integration',
      permissions: [...COMPANY_HRMS_INTEGRATIONS_MANAGE]
    }
  },
  {
    path: 'interview-scorecard-templates',
    component: () => import(/* webpackChunkName: "interviewScorcards" */ '@/views/recruiter/manage-interview-feedback-templates/ListInterviewFeedbackTable.vue'),
    meta: {
      hidden: true,
      title: 'ScoreCard Templates',
      icon: 'dashboard',
      permissions: [...COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_MANAGE],
      requiredAllPermissions: false
    }
  },
  {
    path: 'locations-departments',
    component: () => import(/* webpackChunkName: "LocationsAndDepartments" */ '@/views/settings/location-departments/LocationAndDepartments.vue'),
    meta: {
      hidden: true,
      title: 'Location & departments',
      icon: 'dashboard',
      permissions: [...COMPANY_LOCATIONS_DEPARTMENTS_MANAGE],
      requiredAllPermissions: false
    }
  },
  {
    path: 'notification-preferences',
    component: () => import(/* webpackChunkName: "notificationPreferences" */ '@/views/recruiter/notification-preferences/NotificationPreferences.vue'),
    meta: {
      hidden: true,
      title: 'Notification Preferences',
      icon: 'dashboard',
      permissions: [...USER_NOTIFICATIONS_MANAGE]
    }
  }
]
