
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Banner'
})
export default class extends Vue {
  @Prop({ default: 'bg-red-500' }) bgColor!: string;
  @Prop({ default: 'h-5' }) height!: string;
  @Prop({ default: 'text-white' }) textColor!: string;
}
