export const GeneralArabic = {
  copiedToClipboard: 'تم النسخ إلى الحافظة',
  unableToCopy: 'تعذر النسخ',
  via: 'عبر',
  cancel: 'إلغاء',
  saveChanges: 'حفظ التغييرات',
  move: 'نقل',
  add: 'إضافة',
  deleted: 'تم الحذف',
  delete: 'حذف',
  confirm: 'تأكيد',
  turnOff: 'إيقاف التشغيل',
  turnOn: 'تشغيل',
  to: 'إلى',
  delay: 'تأخير',
  days: 'أيام',
  email: 'الايميل',
  phone: 'الهاتف',
  secret: 'سري',
  visible: 'سري',
  update: 'تحديث',
  close: 'إغلاق',
  replace: 'استبدال',
  continue: 'يكمل',
  save: 'حفظ',
  saveAndClose: 'حفظ وإغلاق',
  fillRequiredFields: 'يرجى ملء جميع الحقول المطلوبة',
  next: 'التالي',
  prev: 'السابق',
  remove: 'إزالة',
  browse: 'تصفح',
  upload: 'تحميل',
  dialog: {
    areYouSureToCancel: 'هل أنت متأكد من الإلغاء؟'
  },
  day: {
    sunday: 'الأحد',
    monday: 'الاثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الأربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    saturday: 'السبت'
  },
  minutes: 'دقائق',
  select: 'يختار',
  reason: 'السبب',
  yes: 'نعم',
  copy: 'نسخ',
  unknown: 'غير معروف',
  edit: 'تعديل',
  all: 'الكل',
  no: 'لا',
  jobAssigned: 'عدد الوظائف المعينة',
  filters: 'الفلاتر',
  clear: 'مسح',
  selected: 'محدد',
  clearFilters: '@:general.filters @:general.clear',
  selectedFilters: '@:general.filters @:general.selected',
  active: 'نشط',
  archived: 'مؤرشف',
  archive: 'الأرشيف',
  unArchive: 'إلغاء الأرشفة ({count}) | جارٍ الغاء الأرشفة...',
  change: 'تغيير ({count}) | جارٍ التغيير...',
  normalChange: 'تغيير | جارٍ التغيير...',
  deleting: 'حذف ({count}) | جارٍ الحذف...',
  archiving: 'أرشفة ({count}) | جارٍ الأرشفة...',
  subject: 'الموضوع',
  done: 'تم',
  settings: 'إعدادات',
  noThanks: 'لا، شكرًا',
  okay: 'حسناً',
  view: 'منظر',
  goBack: 'ارجع',
  submit: 'تقديم',
  ok: 'موافق',
  by: 'بواسطة',
  on: 'على',
  back: 'العودة',
  filterBy: 'تصفية حسب:',
  helpCenter: 'مركز المساعدة',
  optional: '(اختياري)',
  fetchingData: 'جارٍ جلب البيانات...',
  year: 'سنة | سنوات',
  duration: 'المدة',
  about: 'حول',
  supportEmail: 'إذا واجهت أي مشاكل فنية، أرسل بريدًا إلكترونيًا إلى',
  removeIntegration: 'إزالة التكامل',
  integrated: 'مدمج',
  clickHere: 'اضغط هنا',
  byRecruiter: 'بواسطة {recruiter}',
  additionalResources: 'الموارد الإضافية',
  join: 'ينضم',
  jobDescription: 'وصف الوظيفة',
  removeAndChange: 'إزالة وتغيير',
  pleaseAddValidEmails: 'يرجى إضافة بريد إلكتروني صالح!',
  search: 'بحث',
  and: '{first} و {second}',
  supports: 'يدعم{param}',
  progress: 'التقدم',
  mandatory: 'إلزامي',
  slashSeparator: '{first} / {second}',
  referCandidate: 'إحالة مرشح',
  shareReferralLink: 'شارك رابط الإحالة',
  apply: 'قدم طلب',
  showPreview: 'عرض المعاينة',
  signature: 'التوقيع',
  page: 'صفحة',
  reject: 'رفض',
  fullName: 'الاسم الكامل',
  enterNameMessage: 'يرجى إدخال اسمك',
  clickToUpload: 'انقر لتحميل',
  eSignature: 'التوقيع الإلكتروني',
  preview: 'معاينة',
  download: 'تحميل',
  jobClosed: 'تم إغلاق الوظيفة',
  noLongerAcceptingApplications: 'هذه الوظيفة لم تعد تقبل الطلبات.',
  exploreMoreCareers: 'استكشاف المزيد من الوظائف',
  loadMore: 'تحميل المزيد',
  thisActionCannotBeUnDone: 'لا يمكن التراجع عن هذا الإجراء.'
}

export const GeneralEnglish = {
  copiedToClipboard: 'Copied to clipboard',
  unableToCopy: 'Unable to copy',
  via: 'via',
  cancel: 'Cancel',
  saveChanges: 'Save changes',
  move: 'Move',
  add: 'Add',
  deleted: 'Deleted',
  delete: 'Delete',
  confirm: 'Confirm',
  turnOff: 'Turn off',
  turnOn: 'Turn on',
  to: 'To',
  delay: 'Delay',
  days: 'Days',
  email: 'Email',
  phone: 'Phone',
  secret: 'Secret',
  visible: 'Visible',
  update: 'Update',
  close: 'Close',
  replace: 'Replace',
  continue: 'Continue',
  edit: 'Edit',
  save: 'Save',
  saveAndClose: 'Save and close',
  remove: 'Remove',
  browse: 'browse',
  upload: 'Upload',
  fillRequiredFields: 'Please fill all required fields',
  next: 'Next',
  prev: 'Prev',
  dialog: {
    areYouSureToCancel: 'Are you sure to cancel?'
  },
  filterBy: 'Filter by:',
  day: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday'
  },
  minutes: 'minutes',
  select: 'Select',
  reason: 'Reason',
  yes: 'Yes',
  copy: 'Copy',
  subject: 'Subject',
  done: 'Done',
  unknown: 'Unknown',
  all: 'ALL',
  no: 'No',
  jobAssigned: 'Job Assigned',
  settings: 'Settings',
  noThanks: 'No, Thanks',
  okay: 'Okay',
  filters: 'Filters',
  clear: 'Clear',
  selected: 'Selected',
  clearFilters: '@:general.clear @:general.filters',
  selectedFilters: '@:general.selected @:general.filters',
  active: 'Active',
  archived: 'Archived',
  archive: 'Archive',
  unArchive: 'Unarchive ({count}) | Unarchiving...',
  change: 'Change ({count}) | Changing...',
  normalChange: 'Change | Changing...',
  deleting: 'Delete ({count}) | Deleting...',
  archiving: 'Archive ({count}) | Archiving...',
  ok: 'OK',
  view: 'View',
  goBack: 'Go back',
  supportEmail: 'If you encounter any technical issues, send an email to',
  submit: 'Submit',
  by: 'by',
  on: 'on',
  back: 'Back',
  helpCenter: 'help center',
  optional: '(optional)',
  fetchingData: 'Fetching data...',
  removeIntegration: 'Remove Integration',
  integrated: 'Integrated',
  clickHere: 'Click here',
  byRecruiter: 'by {recruiter}',
  jobDescription: 'Job description',
  join: 'Join',
  year: 'year | years',
  duration: 'Duration',
  about: 'About',
  additionalResources: 'Additional Resources',
  careerPage: 'Career page',
  create: 'Create',
  letUsKnow: 'let us know',
  removeAndChange: 'Remove and change',
  search: 'Search',
  pleaseAddValidEmails: 'Please add valid email',
  and: '{first} and {second}',
  supports: 'Supports{param}',
  progress: 'Progress',
  mandatory: 'Mandatory',
  slashSeparator: '{first} / {second}',
  referCandidate: 'Refer candidate',
  shareReferralLink: 'Share referral link',
  apply: 'Apply',
  showPreview: 'Show preview',
  signature: 'Signature',
  page: 'Page',
  reject: 'Reject',
  fullName: 'Full Name',
  enterNameMessage: 'Please enter your name',
  clickToUpload: 'click to upload',
  eSignature: 'E-Signature',
  preview: 'Preview',
  download: 'Download',
  jobClosed: 'Job closed',
  noLongerAcceptingApplications: 'This job is no longer accepting applications.',
  exploreMoreCareers: 'Explore more careers',
  loadMore: 'Load more',
  thisActionCannotBeUnDone: 'This action cannot be undone.'
}
