
export const CompanyBrandEnglish = {
  title: 'Branding',
  subTitle: 'Add your logo and brand color to reflect your branding on the candidate portal.',
  tooltipText: 'Click here to learn more about company branding',
  companyNameTitle: 'Company Name',
  logoTitle: 'Logo',
  colorTitle: 'Color',
  language: 'Default language',
  saveChangesButton: 'Save Changes',
  companyNamePlaceholderText: 'Enter company name',
  upload: {
    replaceLogo: 'Replace logo',
    editLogo: 'Edit logo',
    cropLogo: 'Crop logo',
    deleteLogo: 'Delete logo',
    maxSize: 'The maximum size of file is 2 mb',
    message: 'Updated successfully',
    deleteMessage: 'Removed successfully'
  },
  crop: 'Crop',
  validateCompanyName: 'Please enter company name',
  validateCompanyLanguage: 'Please select company language',
  validatePrimaryColor: 'Please select company primary color',
  errorMessage: {
    saveTheLogo: 'Save the logo'
  },
  copyToClipboard: 'token copied to clipboard',
  editUsingSiteBuilder: 'Edit using site builder',
  useApisToFetch: 'OR use our APIs to fetch a list of your published jobs',
  step1HandlingAPI: '1. To use this option, refer our <a href="{url}" target="_blank" class="underline">Jobs API documentation.</a>',
  step2HandlingAPI: '2. You will need your company ID below to display jobs on your website.',
  warningNote: 'Please note this will change the language for everyone in the company'
}

export const CompanyBrandArabic = {
  title: 'العلامة التجارية',
  subTitle: 'أضف شعارك ولون علامتك التجارية لعرض علامتك التجارية على بوابة المرشحين.',
  tooltipText: 'انقر هنا لـ تعرف على المزيد عن توثيق الشركة',
  companyNameTitle: 'اسم الشركة',
  logoTitle: 'شعار الشركة',
  colorTitle: 'اللون المستخدم في الواجهة',
  language: 'اللغة الافتراضية',
  saveChangesButton: 'حفظ التغييرات',
  companyNamePlaceholderText: 'ادخل اسم الشركة',
  upload: {
    deleteLogo: 'حذف الشعار',
    replaceLogo: 'استبدال الشعار',
    editLogo: 'تحرير الشعار',
    cropLogo: 'شعار المحاصيل',
    maxSize: 'أقصى حجم للملف هو 2 ميغابايت',
    message: 'تم التحديث بنجاح',
    deleteMessage: 'تمت الإزالة بنجاح'
  },
  crop: 'اقتصاص',
  validateCompanyName: 'يرجى إدخال اسم الشركة',
  validateCompanyLanguage: 'يرجى اختيار لغة الشركة',
  validatePrimaryColor: 'يرجى اختيار اللون الأساسي للشركة',
  errorMessage: {
    saveTheLogo: 'حفظ الشعار'
  },
  copyToClipboard: 'تم نسخ الرمز إلى الحافظة',
  editUsingSiteBuilder: 'تحرير باستخدام منشئ المواقع',
  useApisToFetch: 'أو استخدم واجهات برمجة التطبيقات الخاصة بنا لجلب قائمة بالوظائف المنشورة',
  step1HandlingAPI: '1. لاستخدام هذا الخيار، راجع <a href="{url}" target="_blank" class="underline">وثائق واجهة برمجة تطبيقات الوظائف.</a>',
  step2HandlingAPI: '2. ستحتاج إلى معرف شركتك أدناه لعرض الوظائف على موقعك الإلكتروني.',
  warningNote: 'يرجى ملاحظة أن هذا سيؤدي إلى تغيير اللغة للجميع في الشركة'
}
